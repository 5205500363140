import React, { Component } from "react";
import "./assets/styles/fonts.css";
import "./assets/styles/globalVariables.css";
import "./assets/styles/scroll.css";
import "./assets/styles/global.css";
import "./App.css";
import Home from "./web/pages/home/Home";
import OneSong, {
  OnePageRedes,
  OnePageProps,
} from "./web/pages/onesong/OneSong";

import { Switch, Route } from "react-router-dom";

import { GeneralStore } from "./core/GeneralStore";

import { ModalComponent } from "./web/components/modalcomponent/ModalComponent";
import _ from "lodash";
import LoadingPage from "./web/pages/loadingpage/LoadingPage";

import { configure, runInAction } from "mobx";
import { observer, inject } from "mobx-react";
import { OnePage } from "./web/pages/onesong/OneSong";
import * as Icons from "./assets/Icons";

import mirateAlEspejoPortada from "./assets/discos/MirateAlEspejoPortada.png";
import mirateAlEspejoBackground from "./assets/background/MirateAlEspejoPortada.jpeg";

import nvuvPortada from "./assets/discos/NoValePortada.png";
import nvuvBackground from "./assets/background/NoValePortada.jpeg";

import ceciliaPortada from "./assets/discos/CeciliaPortada.png";
import ceciliaBackground from "./assets/background/CeciliaPortada.jpeg";
import Counter from "./web/pages/counter/Counter";
import HomeReserva from "./web/pages/home_reserva/HomeReserva";

configure({
  enforceActions: "observed",
});

const nvuvRedes: OnePageRedes[] = [
  {
    name: "Spotify",
    url: "https://open.spotify.com/album/7tUNyT2TDzzLo7Zb01cHkz",
    icon: Icons.SpotifyIcon,
  },
  {
    name: "Youtube",
    url: "https://www.youtube.com/watch?v=RBb1bR-lkJ0&feature=youtu.be",
    icon: Icons.YouTubeIcon,
  },
];

const nvuvFanPage: OnePageProps = {
  redes: nvuvRedes,
  name: "No Vale Una Victoria",
  releaseYear: "2020",
  audioName: "nvuv.mp3",
  portadaDisco: nvuvPortada,
  background: nvuvBackground,
};

const mirateAlEspejoFanPage: OnePageProps = {
  redes: nvuvRedes,
  name: "Mirate Al Espejo",
  releaseYear: "2021",
  audioName: "mirate-al-espejo.mp3",
  portadaDisco: mirateAlEspejoPortada,
  background: mirateAlEspejoBackground,
};

const ceciliaFanPage: OnePageProps = {
  redes: nvuvRedes,
  name: "Cecilia",
  releaseYear: "2020",
  audioName: "nvuv.mp3",
  portadaDisco: ceciliaPortada,
  background: ceciliaBackground,
};

@inject("routing")
@observer
export default class App extends Component {
  componentDidMount() {}

  render() {
    const generalStore = new GeneralStore();
    const releaseDate = new Date("03/15/2024 21:00:00");

    runInAction(() => (GeneralStore.history = (this.props as any).routing));

    return _.isNil(GeneralStore.history) ? (
      <LoadingPage />
    ) : (
      <div className="App-container">
        <div className="App-switch-container">
          <Switch>
            <Route path="/no-vale-una-victoria">
              <OneSong {...nvuvFanPage} />
            </Route>
            <Route path="/mirate-al-espejo">
              <OneSong {...mirateAlEspejoFanPage} />
            </Route>
            {/* <Route path="/cecilia">
              <OneSong {...ceciliaFanPage} />
            </Route> */}

            <Route path="/home">
              <HomeReserva />
            </Route>
            <Route path="/">
              {new Date() < releaseDate? <Counter/>:<HomeReserva/>}
            </Route>
          </Switch>
        </div>
        {!_.isNil(generalStore.modalContent) && <ModalComponent />}
      </div>
    );
  }
}
