import { observable, action, computed, runInAction } from "mobx";
import { ApiClient } from "./ApiClient";
import _ from "lodash";
import { InstagramPost, InstagramMediaType } from "./models/IntagramPost";

export enum AppMode {
  Mobile = 0,
  Desktop = 1
}

export interface ModalContent {
  title?: React.ReactNode,
  content: React.ReactNode,
  footer?: React.ReactNode,
  onOk?: any,
  onCancel?: any
  contentFix?: boolean
}

export class GeneralStore {
  private static instance: GeneralStore | null = null;

  @observable
  mode = AppMode.Desktop;

  @observable
  currentHeight: number = 0;

  @observable
  currentWidth: number = 0;

  @observable.deep
  modalContent?: ModalContent = undefined;

  @observable.deep
  static history: any;

  @observable.deep
  instagramPosts: InstagramPost[] = [];

  instagramNextUrl: string | undefined = "https://graph.instagram.com/me/media?fields=id,caption,permalink,thumbnail_url,media_type,media_url,username,timestamp&access_token=IGQVJYd01leGpiU2tMU05VblBDeWppMDhwLVNpMTY5bFpzcmEzaXZARNHhsSkd5aXQ4bG9jbDJoVEZAZAUkNUQVRya0o0T3lmSWk2WVIzc080Q2tHSzF5M1luT1RQeFlOODRndTVfRV93";

  isDraggingPosts: boolean = false;
  isLoadingPosts: boolean = false;

  constructor() {
    if (GeneralStore.instance != null) return GeneralStore.instance;

    GeneralStore.instance = this;

    this.navigateTo = this.navigateTo.bind(this);

    window.addEventListener("resize", this.onResize.bind(this));

    this.onResize();

    return GeneralStore.instance;
  }

  @computed
  get location() {
    return GeneralStore.history.location;
  }

  @action
  onResize() {
    if (window.innerWidth <= 780)
      this.mode = AppMode.Mobile
    else
      this.mode = AppMode.Desktop

    this.currentWidth = window.innerWidth;
    this.currentHeight = window.innerHeight;
  }

  @action
  async fetchNextInstagramPosts() {
    if (!_.isNil(this.instagramNextUrl) && !this.isLoadingPosts) {
      this.isLoadingPosts = true;
      const response = await ApiClient.fetchInstagramPosts({ url: this.instagramNextUrl })

      if (this.instagramNextUrl !== response?.next)
        this.instagramNextUrl = response?.next;
      else
        this.instagramNextUrl = undefined;

      response?.posts.forEach((post) => {
        if (post.media_type !== InstagramMediaType.VIDEO)
          runInAction(() => {
            this.instagramPosts.push(post);
          })
      })
      this.isLoadingPosts = false;
    }
  }

  @action
  showModal(modalContent: ModalContent) {
    this.modalContent = modalContent;
  }

  @action
  hideModal() {
    this.modalContent = undefined;
  }

  @action
  navigateTo(ruta: string, borrarRutaActual: boolean = false) {
    GeneralStore.history.push(ruta);
  }

  @action
  navigateBack() {
    GeneralStore.history.pop();
  }
}
