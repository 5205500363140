import { BaseModel } from "./BaseModel";

export enum InstagramMediaType {
  IMAGE = "IMAGE",
  VIDEO = "VIDEO",
  CAROUSEL_ALBUM = "CAROUSEL_ALBUM",
}

export class InstagramPost extends BaseModel {
  permalink: string = "";
  media_type: InstagramMediaType = InstagramMediaType.VIDEO;
  media_url: string = "";
}
