import React from 'react';
import { observer } from 'mobx-react';
import { BaseComponent } from '../../BaseComponent';
import { Footer } from '../../components/footer/Footer';

import _ from 'lodash';

import './OneSong.css';

import ReactAudioPlayer from 'react-audio-player';

import { CaretRightOutlined, PauseOutlined } from '@ant-design/icons'

export interface OnePageRedes {
  icon: (className: string) => any;
  name: string;
  url: string;
}

export interface OnePageProps {
  portadaDisco?: any;
  background?: any;
  audioName?: string;
  releaseYear?: string;
  name?: string;

  redes?: OnePageRedes[]
}

interface OnePageState {
  currentScrollTop: number,
  reproduciendo: boolean
}

@observer
export class OnePage extends BaseComponent<OnePageProps, OnePageState> {

  state: OnePageState = { currentScrollTop: 0, reproduciendo: false };

  rap: any;

  constructor(props: OnePageProps) {
    super(props);

    this.handlePlayMusic = this.handlePlayMusic.bind(this);
    this.handlePauseMusic = this.handlePauseMusic.bind(this);

  }

  handlePlayMusic(state?: any) {
    this.rap.audioEl.current.play();
    this.setState({ reproduciendo: true })
  }

  handlePauseMusic(state?: any) {
    this.rap.audioEl.current.pause();

    this.setState({ reproduciendo: false });
  }


  renderRedes(red: OnePageRedes) {
    return (<a style={{ textDecoration: "none" }} target={"_blank"} href={red.url}><div className="OnePage-redes-container-button"><div className="OnePage-redes-button">{red.icon("OnePage-redes-button-icon")}<div>{red.name}</div></div></div></a>)
  }



  render() {
    const { redes, background, portadaDisco, audioName, name, releaseYear } = this.props;

    const { reproduciendo } = this.state;

    return (
      <>
        <div className="OnePage-container">
          <div className="OnePage-top-section">
            <img src={background!} className="OnePage-container-background" />
            <div className="OnePage-container-portada">

              <div className={"OnePage-portada-container"}>
                <img src={portadaDisco} className="OnePage-portada" />
                <div className="OnePage-play-button-container " onClick={() => { reproduciendo ? this.handlePauseMusic() : this.handlePlayMusic() }}>
                  {reproduciendo ? <PauseOutlined className="OnePage-play-button" /> : <CaretRightOutlined className="OnePage-play-button" />}
                </div>
              </div>
              <ReactAudioPlayer
                ref={(element) => { this.rap = element; }}
                src={require(`./../../../assets/music/${audioName}`)}
                controls
                onPlay={() => this.setState({ reproduciendo: true })}
                onPause={() => this.setState({ reproduciendo: false })}
              />
            </div>
          </div>
          <div className="OnePage-by-section">
            <div className="OnePage-by-title">{name}</div>
            <div className="OnePage-by-banda">por <a href="/" onClick={() => { this.generalStore.navigateTo("/") }}>Sin Palabras</a></div>
          </div>
          <div className="OnePage-button-list-container">
            <div className="OnePage-button-text">Disponible en:</div>
            <div className="OnePage-button-list">{redes!.map((r) => this.renderRedes(r))}</div>
            <div className="OnePage-button-text">{`Lanzada en ${releaseYear}`}</div>
          </div>

        </div>
        <Footer />
      </>
    );
  }
}

export default OnePage;