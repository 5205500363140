import React from 'react';
import { observer } from 'mobx-react';
import { BaseComponent } from '../../BaseComponent';
import { Spin } from 'antd';


interface LoadingProps {
  title?: string,
}
interface LoadingState { }

@observer
export class LoadingPage extends BaseComponent<LoadingProps, LoadingState> {

  state: LoadingState = {};

  render() {

    return (

      <div style={{ height: "100%" }}>
        <Spin />
      </div>

    );
  }
}

export default LoadingPage;