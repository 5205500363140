import React from "react";
import { observer } from "mobx-react";
import { BaseComponent } from "../../BaseComponent";

import "./Counter.css";

const PSLogo = require("./../../../assets/SPLogo.svg")

interface CounterProps { }
interface CounterState {
  currentDate: Date;
  reproduciendo: boolean;
}

@observer
export class Counter extends BaseComponent<CounterProps, CounterState> {
  state: CounterState = { currentDate: new Date(), reproduciendo: true };

  releaseDate = new Date("03/15/2024 21:00:00");

  constructor(props: CounterProps) {
    super(props);

    setInterval(() => { this.setState({ currentDate: new Date() }) }, 1000);
  }

  NumToTime(num: number) {
    let seg = Math.ceil(num / 1000);
    let minutes = Math.ceil(seg / 60);
    let hours = Math.ceil(minutes / 60);
    let days = Math.ceil(hours / 24);
    let weeks = Math.ceil(days / 7);

    // var hours = Math.floor(num / 60);  

    return (weeks-1) + "w:" + ((days-1) % 7) + "d:" + ((hours-1) % 24).toString().padStart(2, "0") + "h:" + (minutes % 60).toString().padStart(2, "0") + "m:" + (seg % 60).toString().padStart(2, "0") + "s";
  }

  getInstagramIcon() {
    //@ts-ignore
    return (<svg className="Counter-icon" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512 512" space="preserve">
      <g>
        <g>
          <path d="M352,0H160C71.648,0,0,71.648,0,160v192c0,88.352,71.648,160,160,160h192c88.352,0,160-71.648,160-160V160
       C512,71.648,440.352,0,352,0z M464,352c0,61.76-50.24,112-112,112H160c-61.76,0-112-50.24-112-112V160C48,98.24,98.24,48,160,48
       h192c61.76,0,112,50.24,112,112V352z"/>
        </g>
      </g>
      <g>
        <g>
          <path d="M256,128c-70.688,0-128,57.312-128,128s57.312,128,128,128s128-57.312,128-128S326.688,128,256,128z M256,336
       c-44.096,0-80-35.904-80-80c0-44.128,35.904-80,80-80s80,35.872,80,80C336,300.096,300.096,336,256,336z"/>
        </g>
      </g>
      <g>
        <g>
          <circle cx="393.6" cy="118.4" r="17.056" />
        </g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
    </svg>)
  }


  getSpotifyIcon() {
    return (<svg id="Bold" className="Counter-icon" enableBackground="new 0 0 24 24" height="512" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg"><path d="m12 24c6.624 0 12-5.376 12-12s-5.376-12-12-12-12 5.376-12 12 5.376 12 12 12zm4.872-6.344v.001c-.807 0-3.356-2.828-10.52-1.36-.189.049-.436.126-.576.126-.915 0-1.09-1.369-.106-1.578 3.963-.875 8.013-.798 11.467 1.268.824.526.474 1.543-.265 1.543zm1.303-3.173c-.113-.03-.08.069-.597-.203-3.025-1.79-7.533-2.512-11.545-1.423-.232.063-.358.126-.576.126-1.071 0-1.355-1.611-.188-1.94 4.716-1.325 9.775-.552 13.297 1.543.392.232.547.533.547.953-.005.522-.411.944-.938.944zm-13.627-7.485c4.523-1.324 11.368-.906 15.624 1.578 1.091.629.662 2.22-.498 2.22l-.001-.001c-.252 0-.407-.063-.625-.189-3.443-2.056-9.604-2.549-13.59-1.436-.175.048-.393.125-.625.125-.639 0-1.127-.499-1.127-1.142 0-.657.407-1.029.842-1.155z" /></svg>);
  }

  getYoutubeIcon() {
    return (<svg className="Counter-icon" height="682pt" viewBox="-21 -117 682.66672 682" width="682pt" xmlns="http://www.w3.org/2000/svg"><path d="m626.8125 64.035156c-7.375-27.417968-28.992188-49.03125-56.40625-56.414062-50.082031-13.703125-250.414062-13.703125-250.414062-13.703125s-200.324219 0-250.40625 13.183593c-26.886719 7.375-49.03125 29.519532-56.40625 56.933594-13.179688 50.078125-13.179688 153.933594-13.179688 153.933594s0 104.378906 13.179688 153.933594c7.382812 27.414062 28.992187 49.027344 56.410156 56.410156 50.605468 13.707031 250.410156 13.707031 250.410156 13.707031s200.324219 0 250.40625-13.183593c27.417969-7.378907 49.03125-28.992188 56.414062-56.40625 13.175782-50.082032 13.175782-153.933594 13.175782-153.933594s.527344-104.382813-13.183594-154.460938zm-370.601562 249.878906v-191.890624l166.585937 95.945312zm0 0" /></svg>);
  }

  render() {
    return (
      <>
        <div className="Counter-logo">
          <img src={PSLogo} />
        </div>
        <div id="clock">
          <p className="date">{"15/03/2024"}</p>
          <p className="time">{this.NumToTime(((this.releaseDate as any) - (this.state.currentDate as any)) as any)}</p>
          {/* <p className="text">DIGITAL CLOCK with Vue.js</p> */}
          <div className="Counter-icon-container">
            <a href={"https://www.instagram.com/sinpalabrasuy/"} rel="noopener noreferrer" target="_blank" className="Counter-icon-container instagram-icon"><span style={{ display: "none" }}>Instagram Link</span>{this.getInstagramIcon()}</a>
            <a href={"https://open.spotify.com/artist/0zl7pTozrK2eqxwB6xgX9S"} rel="noopener noreferrer" target="_blank" className="Counter-icon-container spotify-icon"><span style={{ display: "none" }}>Spotify Link</span>{this.getSpotifyIcon()}</a>
            <a href={"https://www.youtube.com/c/sinpalabrasuy/"} rel="noopener noreferrer" target="_blank" className="Counter-icon-container youtube-icon"><span style={{ display: "none" }}>Youtube Link</span>{this.getYoutubeIcon()}</a>
          </div>
        </div>
      </>
    );
  }
}

export default Counter;
