import React from "react";
import { observer } from "mobx-react";
import { BaseComponent } from "../../BaseComponent";

// import emailjs from '@emailjs/browser';

import "./HomeReserva.css";
import YouTube from "react-youtube";
import { AppMode } from "../../../core/GeneralStore";
import { Footer } from "../../components/footer/Footer";

// Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// import {getFirestore} from "firebase/firestore";

// const PSLogo = require("./../../../assets/SPLogo.svg")
const ReservaBackground = require("./../../../assets/background_reserva.svg");
const ReservaPortada = require("./../../../assets/reserva_portada.png");
const LogoHeader = require("./../../../assets/logo_header.svg");
const hamburgerMenu = require("./../../../assets/iconos/Hamburger_icon.svg");



// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyB2M3nRDo8qkwm_R4pdmzkDvzDVLlCa-lc",
  authDomain: "sp-newsletter-74e03.firebaseapp.com",
  projectId: "sp-newsletter-74e03",
  storageBucket: "sp-newsletter-74e03.appspot.com",
  messagingSenderId: "30929577777",
  appId: "1:30929577777:web:73abbd55fac5e09451b210",
  measurementId: "G-RZ16ZZL9CV"
};

// Initialize Firebase
// const app = initializeApp(firebaseConfig);
// // const analytics = getAnalytics(app);
// const db = getFirestore(app);



interface HomeReservaProps { }
interface HomeReservaState {
  showHeader: boolean,
  showMainContent: boolean
}

interface HeaderReservaProps {
  show: boolean;
}
interface HeaderReservaState {
}

interface NewsLetterProps {
}
interface NewsLetterState {
  email:string;
}


export class HeaderReserva extends BaseComponent<HeaderReservaProps, HeaderReservaState>{

  constructor(props: HeaderReservaProps) {
    super(props);
  }

  render() {
    const { show } = this.props;

    return <div className={"HomeReserva-header " + (show ? "HomeReserva-header-visible " : "")}>
      <img src={LogoHeader} className="HomeReserva-logo-header" />
      {/* <img src={hamburgerMenu} className="HomeReserva-hamburguer-header" /> */}
    </div>
  }
}

// export class NewsLetterReserva extends BaseComponent<NewsLetterProps, NewsLetterState>{
//   state:NewsLetterState ={
//     email:"",
//   }

//   constructor(props: HeaderReservaProps) {
//     super(props);

   
//   }

//   render() {
//     const {email} = this.state;

//     const sendEmail = (e:any) => {
//         e.preventDefault();

//         const templateParams = {
//           message: "Agluien quiere unirse a la NewsLetter '" + this.state.email + "'" 
//         }
    
//         // emailjs
//         //   .send('YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', templateParams, {
//         //     publicKey: 'YOUR_PUBLIC_KEY',
//         //   })
//         //   .then(
//         //     () => {
//         //       console.log('SUCCESS!');
//         //     },
//         //     (error) => {
//         //       console.log('FAILED...', error.text);
//         //     },
//         //   );
//       };

//     return <div className={""}>
//       <input value={email} onChange={e=>console.log(e.currentTarget.value)}/>
      
//     </div>
//   }
// }

@observer
export class HomeReserva extends BaseComponent<HomeReservaProps, HomeReservaState> {
  state: HomeReservaState = {
    showHeader: false,
    showMainContent: true, 
  };

  image: any = React.createRef() as any;

  constructor(props: HomeReservaProps) {
    super(props);

    this.image = React.createRef() as any;

    document.body.addEventListener("scroll", (event: any) => {
      console.log(window.scrollY);
      // let x = (innerWidth / 2 - rotate.pageX) / 15;
      // let y = (innerHeight / 2 - rotate.pageY) / 15;
      // this.image.style.transform = "rotateY(" + x + "deg) rotateX(" + y + "deg)";
    });

  }

  componentDidMount(): void {

  }

  handlerOnScroll(posY: number) {

    const { showHeader, showMainContent, } = this.state;

    //Show Header at 30px
    if (posY > 30 && !showHeader) {
      this.setState({ showHeader: true })
    } else if ((posY <= 30 && showHeader)) {
      this.setState({ showHeader: false })
    }


    //Show Main content
    if (posY > 150 && showMainContent) {
      this.setState({ showMainContent: false })
    } else if (posY <= 150 && !showMainContent) {
      this.setState({ showMainContent: true })

    }

  }

  imageMouseMove(rotate: any) {
    // console.log("ENTREEE", rotate.pageY - this.image.current.y);
    // let innerWidth=this.image.current.width;
    // let innerHeight = this.image.current.height;
    // let x = (innerWidth / 2 - (rotate.pageX - this.image.current.x)) / 15;
    // let y = (innerHeight / 2 - (rotate.pageY - this.image.current.y)) / 15;

    // this.image.current.style.transform = "rotateY(" + x + "deg) rotateX(" + y + "deg) scale(" + 105 + "%)";
  }

  imageMouseOver() {
    // this.image.current.style.transition = "all 0.2s ease";
  }

  imageMouseOut() {
    // this.image.current.style.transform = "rotateY(0deg) rotateX(0deg)";
    // this.image.current.style.transition = "all 0.5s ease";
  }

  getInstagramIcon() {
    //@ts-ignore
    return (<svg className="Counter-icon" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512 512" space="preserve">
      <g>
        <g>
          <path d="M352,0H160C71.648,0,0,71.648,0,160v192c0,88.352,71.648,160,160,160h192c88.352,0,160-71.648,160-160V160
       C512,71.648,440.352,0,352,0z M464,352c0,61.76-50.24,112-112,112H160c-61.76,0-112-50.24-112-112V160C48,98.24,98.24,48,160,48
       h192c61.76,0,112,50.24,112,112V352z"/>
        </g>
      </g>
      <g>
        <g>
          <path d="M256,128c-70.688,0-128,57.312-128,128s57.312,128,128,128s128-57.312,128-128S326.688,128,256,128z M256,336
       c-44.096,0-80-35.904-80-80c0-44.128,35.904-80,80-80s80,35.872,80,80C336,300.096,300.096,336,256,336z"/>
        </g>
      </g>
      <g>
        <g>
          <circle cx="393.6" cy="118.4" r="17.056" />
        </g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
    </svg>)
  }

  getSpotifyIcon() {
    return (<svg id="Bold" className="Counter-icon" enableBackground="new 0 0 24 24" height="512" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg"><path d="m12 24c6.624 0 12-5.376 12-12s-5.376-12-12-12-12 5.376-12 12 5.376 12 12 12zm4.872-6.344v.001c-.807 0-3.356-2.828-10.52-1.36-.189.049-.436.126-.576.126-.915 0-1.09-1.369-.106-1.578 3.963-.875 8.013-.798 11.467 1.268.824.526.474 1.543-.265 1.543zm1.303-3.173c-.113-.03-.08.069-.597-.203-3.025-1.79-7.533-2.512-11.545-1.423-.232.063-.358.126-.576.126-1.071 0-1.355-1.611-.188-1.94 4.716-1.325 9.775-.552 13.297 1.543.392.232.547.533.547.953-.005.522-.411.944-.938.944zm-13.627-7.485c4.523-1.324 11.368-.906 15.624 1.578 1.091.629.662 2.22-.498 2.22l-.001-.001c-.252 0-.407-.063-.625-.189-3.443-2.056-9.604-2.549-13.59-1.436-.175.048-.393.125-.625.125-.639 0-1.127-.499-1.127-1.142 0-.657.407-1.029.842-1.155z" /></svg>);
  }

  getYoutubeIcon() {
    return (<svg className="Counter-icon" height="682pt" viewBox="-21 -117 682.66672 682" width="682pt" xmlns="http://www.w3.org/2000/svg"><path d="m626.8125 64.035156c-7.375-27.417968-28.992188-49.03125-56.40625-56.414062-50.082031-13.703125-250.414062-13.703125-250.414062-13.703125s-200.324219 0-250.40625 13.183593c-26.886719 7.375-49.03125 29.519532-56.40625 56.933594-13.179688 50.078125-13.179688 153.933594-13.179688 153.933594s0 104.378906 13.179688 153.933594c7.382812 27.414062 28.992187 49.027344 56.410156 56.410156 50.605468 13.707031 250.410156 13.707031 250.410156 13.707031s200.324219 0 250.40625-13.183593c27.417969-7.378907 49.03125-28.992188 56.414062-56.40625 13.175782-50.082032 13.175782-153.933594 13.175782-153.933594s.527344-104.382813-13.183594-154.460938zm-370.601562 249.878906v-191.890624l166.585937 95.945312zm0 0" /></svg>);
  }

  renderVideo() {
    const ratio = 700 / 411;

    const widthVideo: any =
      this.generalStore.mode === AppMode.Desktop
        ? "700"
        : this.generalStore.currentWidth;
    const heightVideo: any =
      this.generalStore.mode === AppMode.Desktop
        ? "411"
        : Math.min(widthVideo / ratio, 385);

    const opts = {
      height: heightVideo,
      width: widthVideo,
      playerVars: {
        // https://developers.google.com/youtube/player_parameters
        autoplay: 0,
      },
    };

    //@ts-ignore
    return (
      <YouTube
        videoId="MxxKJTDWPzE"
        opts={opts as any}
      // onPlay={this.handlePlayMusic}
      // onPause={this.handlePauseMusic}
      />
    );
  }

  render() {
    const { showMainContent, showHeader } = this.state;

    return (
      <>
        <img src={ReservaBackground} className="HomeReserva-background" />
        <HeaderReserva show={showHeader} />
        <div className={"HomeReserva-section HomeReserva-section-change transition " + (!showMainContent ? "transparent" : "")} style={{ position: "absolute", pointerEvents: "none", zIndex: 2 }}>
          <div className={"HomeReserva-subsection"}>
            <img ref={this.image} src={ReservaPortada} className="HomeReserva-portada" onMouseOver={(e) => this.imageMouseOver()} onMouseMove={(e) => this.imageMouseMove(e)} onMouseOut={() => this.imageMouseOut()} />
          </div>
          <div className={"HomeReserva-subsection HomeReserva-subsectionRight"}>
            <div className={"HomeReserva-text-container"}>
              <h1 className="HomeReserva-text HomeReserva-title">RESERVA DE AMOR</h1>
              <h2 className="HomeReserva-text HomeReserva-subtitle">15 DE MARZO</h2>
              <a href={"/#video"} className="HomeReserva-text HomeReserva-button">ESCUCHAR!</a>
            </div>
          </div>
        </div>
        <div className="HomeReserva-background-layout" onScroll={(e) => this.handlerOnScroll(e.currentTarget.scrollTop)}>
          <div className="HomeReserva-section"></div>
          <div className="HomeReserva-section">
            <div id="video" className="HomeReserva-videosection">
              <h2 className="HomeReserva-text HomeReserva-subtitle-section">VIDEO</h2>
              {this.renderVideo()}
            </div>
            
          </div>
          {/* {<Footer/>} */}
          {/* <div className="HomeReserva-section" style={{ justifyContent: "center", alignItems: "center" }}>

            <div style={{ display: "flex", justifyContent: "center", alignContent: "center", flexDirection: "column", textAlign: "center", padding: "50px 70px", borderRadius:"30px", background:"#00000066"}}>
              <h2 className="HomeReserva-text HomeReserva-subtitle-section HomeReserva-disco-section">NUEVO DISCO</h2>
              <h2 className="HomeReserva-text HomeReserva-subtitle-section HomeReserva-disco-section">Silencio</h2>
              <h2 className="HomeReserva-text HomeReserva-subtitle-section HomeReserva-disco-section">2024</h2>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div style={{ width: "200px", display: "flex", justifyContent: "space-between" }}>
                  <a href="https://www.instagram.com/sinpalabrasuy">
                    {this.getInstagramIcon()}
                  </a>
                  <a href="https://open.spotify.com/intl-es/artist/0zl7pTozrK2eqxwB6xgX9S">
                    {this.getSpotifyIcon()}
                  </a>
                  <a href="https://www.youtube.com/@sinpalabrasuy">
                    {this.getYoutubeIcon()}
                  </a>
                </div>
              </div>

            </div>


          </div> */}

          {/* <div className="HomeReserva-section" style={{ justifyContent: "center", alignItems: "center" }}>

            <div style={{ display: "flex", justifyContent: "center", alignContent: "center", flexDirection: "column", textAlign: "center", padding: "50px 70px", borderRadius:"30px", background:"#00000066"}}>
              <h2 className="HomeReserva-text HomeReserva-subtitle-section HomeReserva-disco-section">NUEVO DISCO</h2>
              <NewsLetterReserva/>
              

            </div>


          </div> */}

          
          {/* <div className="HomeReserva-background1"></div>
          <div className="HomeReserva-background2"></div>
          <div className="HomeReserva-background3"></div> */}
        

        </div>

      </>
    );
  }
}

export default HomeReserva;
