import React from "react";
import { observer, Observer } from "mobx-react";
import { BaseComponent } from "../../BaseComponent";

import "./ModalComponent.css";
import { bind } from "lodash";

interface ModalComponentProps { }
interface ModalComponentState { }

@observer
export class ModalComponent extends BaseComponent<ModalComponentProps, ModalComponentState> {

  constructor(props: any) {
    super(props);

    this.handleEsc = this.handleEsc.bind(this);
  }

  componentDidMount() {
    document.addEventListener("keydown", this.handleEsc)
  }


  handleEsc(e: any) {
    console.log(e);
    if (e.keyCode === 27) {
      this.generalStore.hideModal();
      document.removeEventListener("keydown", this.handleEsc);
    }
  }

  private getCloseIcon() {
    return (<svg className="Modal-close-icon" id="Capa_1" enable-background="new 0 0 386.667 386.667" height="512" viewBox="0 0 386.667 386.667" width="512" xmlns="http://www.w3.org/2000/svg"><path d="m386.667 45.564-45.564-45.564-147.77 147.769-147.769-147.769-45.564 45.564 147.769 147.769-147.769 147.77 45.564 45.564 147.769-147.769 147.769 147.769 45.564-45.564-147.768-147.77z" /></svg>);
  }

  render() {

    return (<Observer>{() => (<div
      className="Modal-container"
      onClick={() => { this.generalStore.hideModal() }}
    >
      <div className={`Modal-wrapper ${this.generalStore.modalContent?.contentFix ? "Modal-not-content-fix" : ""}`} onClick={(e) => { e.stopPropagation() }}>
        <div className="Modal-close-icon-container" onClick={() => { this.generalStore.hideModal() }}>{this.getCloseIcon()}</div>
        {this.generalStore.modalContent?.content}
      </div>

    </div>)}</Observer>);
  }
}
