import { InstagramPost } from "./models/IntagramPost";

export interface InstagramPostsRequest {
  url: string;
}

export interface InstagramPostsResponse {
  next: string;
  posts: InstagramPost[];
}

//
//

export class ApiClient {
  static async fetchInstagramPosts(request: InstagramPostsRequest) {
    const config = {
      method: "GET",
    };

    return await fetch(request.url, config).then(async (httpResponse) => {
      if (httpResponse.status === 200) {
        const data: any = await httpResponse.json();

        console.log(data);

        const response: InstagramPostsResponse = { next: "", posts: [] };

        response.next = data.paging.next;

        data.data.forEach((element: any) => {
          response.posts.push(element);
        });

        return response;
      }
    });
  }
}
